<template>
  <div class="page-box">
    <div class="lang-box">
      <el-radio-group v-model="lang" size="medium"  @change="goHref">
        <el-radio-button label="中文"></el-radio-button>
        <el-radio-button label="English"></el-radio-button>
      </el-radio-group>
    </div>
    <el-scrollbar ref="view-box" style="position: relative;flex: 1;height:100%;box-sizing: border-box;">
      <androidXOnHoverFile_enUS v-if="lang=='English'" ></androidXOnHoverFile_enUS>
      <androidXOnHoverFile_zhCH v-else ></androidXOnHoverFile_zhCH>
    </el-scrollbar>
  </div>
</template>

<script>
import androidXOnHoverFile_zhCH from "@/components/documentsBase/template/androidXOnHoverFile_zhCH";
import androidXOnHoverFile_enUS from "@/components/documentsBase/template/androidXOnHoverFile_enUS";
export default {
  components: { androidXOnHoverFile_zhCH,androidXOnHoverFile_enUS},
  metaInfo: {
    title: "ITGSA | 移动智能终端生态联盟 | 地震预警服务规范",
    meta: [
      {
        name: "keywords",
        content: "ITGSA,生态联盟,金标认证,地震预警服务规范",
      },
      {
        name: "description",
        content:
          "Android应用的稳定性是构建Android应用体验的重要一环，直接影响用户体验，本标准旨在构建一套Android应用稳定性测试衡量方法，牵引生态内所有应用的稳定性质量改进，构建共同的稳定和体验良好的应用生态。",
      },
    ],
  },
  data() {
    return {
      lang: this.$route.query.lang?this.$route.query.lang:'中文',
    };
  },
  methods: {
    goHref(value){
      this.$router.push({ path:'/documentsBase/androidXOnHoverFile',query:{lang:value}});
    },
  },
};
</script>

<style scoped lang="scss">

/*主体*/
.page-box{
  position: relative;
  display: flex;
  .lang-box{
    position: absolute;
    right: 0;
    top: -45px;
  }
  .view-box{
    position: relative;
    padding: 60px;
  }
}
@media screen and (max-width: 800px) {
    .page-box{
      .view-box{
        padding: 10px;
        .w400, .w750{
          width: 100% !important;
        }
        .p{
          padding-left: 1rem !important;
          word-break:break-all;
        }
      }
    }
}
</style>
