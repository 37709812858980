<template>
  <div class="view-box">
    <h1 class="h1 text-center margin-b30">
      ITGSA Instructions for Hover Listening with android T/Without androidX
    </h1>
    <p class="p margin-b30" >
      For applications that cannot be upgraded to androidx for the time being, an approach is provided to bypass androidx and achieve hover listening. As a temporary transition scheme, it is recommended that the database field listening should be switched to standard listening method of androidx. For the standard listening method, please refer to: Section 4.4.4 (Foldable Screen State Listen and Acquisition) in 
      <a
        class="a"
        href="https://www.itgsa.com/documentsBase/whiteBookFile#3-4-4"
      >
        ITGSA Large Screen Device Application Adaptation White Paper
      </a>
    </p>
    <p class="p margin-b10">Demo code:</p>  
    <div class="code margin-b10">
      <p class="p ">private static final Pattern FEATURE_PATTERN = Pattern.compile("([a-z]+)-\\[(\\d+),(\\d+),(\\d+),(\\d+)]-?(flat|half-opened)?");</p>  
      <p class="p ">private static final String FEATURE_TYPE_FOLD = "fold";</p>  
      <p class="p ">private static final String FEATURE_TYPE_HINGE = "hinge";</p>  
      <p class="p ">private static final String PATTERN_STATE_FLAT = "flat"; </p>  
      <p class="p ">private static final String PATTERN_STATE_HALF_OPENED = "half-opened";</p>  
      <br>
      <br>
      <p class="p ">private static final String DISPLAY_FEATURES = "display_features";</p>  
      <p class="p ">private final Uri mDisplayFeaturesUri = Settings.Global.getUriFor(DISPLAY_FEATURES);</p>  
      <p class="p ">private ContentResolver mResolver;</p>  
      <p class="p ">private ContentObserver mObserver;</p>  
      <br>
      <br>
      <p class="p ">@Override</p>  
      <p class="p ">protected void onCreate(Bundle savedInstanceState) {</p> 
      <p class="p padding-l30"> super.onCreate(savedInstanceState);</p> 
      <p class="p padding-l30"> mObserver = new SettingsObserver();</p> 
      <p class="p padding-l30"> notifyFoldingFeature();</p> 
      <p class="p padding-l30"> //onCreate 注册数据库监听</p> 
      <p class="p padding-l30"> mResolver.registerContentObserver(mDisplayFeaturesUri, false /* notifyForDescendants */,
        mObserver /* ContentObserver */);</p> 
      <p class="p ">}</p> 
      <br>
      <br>
      <p class="p ">@Override</p>  
      <p class="p ">public void onConfigurationChanged(@NonNull Configuration newConfig) {</p> 
      <p class="p padding-l30"> super.onConfigurationChanged(newConfig);</p> 
      <p class="p padding-l30"> notifyFoldingFeature();</p> 
      <p class="p ">}</p> 
      <p class="p ">@Override</p>  
      <p class="p ">protected void onDestroy() {</p> 
      <p class="p padding-l30"> super.onDestroy();</p> 
      <p class="p padding-l30"> //onDestroy 反注册数据库监听</p> 
      <p class="p padding-l30"> mResolver.unregisterContentObserver(mObserver);</p> 
      <p class="p ">}</p> 
      <p class="p ">private final class SettingsObserver extends ContentObserver {</p> 
      <p class="p padding-l30"> SettingsObserver() {</p> 
      <p class="p padding-l60"> super(new Handler(Looper.getMainLooper()));</p> 
      <p class="p padding-l30"> }</p> 
      <p class="p padding-l30"> @Override</p> 
      <p class="p padding-l30"> public void onChange(boolean selfChange, Uri uri) {</p> 
      <p class="p padding-l60"> if (mDisplayFeaturesUri.equals(uri)) {</p> 
      <p class="p padding-l120"> notifyFoldingFeature();</p> 
      <p class="p padding-l60"> }</p> 
      <p class="p padding-l30"> }</p> 
      <p class="p ">}</p> 
      <br>
      <br>
      <p class="p ">//onCreate、onConfigurationChanged和数据库变化onChange的时候调用</p> 
      <p class="p ">//这里主要是获取数据库中折叠屏状态数据，并根据屏幕大小进行转换</p> 
      <p class="p ">private void notifyFoldingFeature() {</p> 
      <p class="p padding-l30"> WindowMetrics maxWindowMetrics = this.getWindowManager().getMaximumWindowMetrics();</p> 
      <p class="p padding-l30"> Rect currentMaxWindowRect = maxWindowMetrics.getBounds();</p> 
      <p class="p padding-l30"> CommonFoldingFeature foldingFeature = parseFromString(getFeatureString());</p> 
      <p class="p padding-l30"> //折叠态为null</p> 
      <p class="p padding-l30"> if (foldingFeature == null) {</p> 
      <p class="p padding-l60"> return;</p> 
      <p class="p padding-l30"> }</p> 
      <p class="p padding-l30"> Rect foldingBounds = foldingFeature.getBounds();</p> 
      <p class="p padding-l30"> //由于数据库中只写ROTATION为0的值，因此这里需要在旋转屏幕的时候做转换</p> 
      <p class="p padding-l30"> //rotation为0是水平半折的机器</p> 
      <p class="p padding-l30"> Rect foldingBounds = foldingFeature.getBounds();</p> 
      <p class="p padding-l30"> if (foldingBounds.width() > foldingBounds.height()) {</p> 
        <p class="p padding-l60"> if (currentMaxWindowRect.width() != foldingBounds.width()) {</p> 
          <p class="p padding-l90"> int oriLeft = foldingBounds.left;</p> 
          <p class="p padding-l90"> int oriRight = foldingBounds.right;</p> 
          <p class="p padding-l90"> foldingBounds.left = foldingBounds.top;</p> 
          <p class="p padding-l90"> foldingBounds.top = oriLeft;</p> 
          <p class="p padding-l90"> foldingBounds.right = foldingBounds.bottom;</p> 
          <p class="p padding-l90">  foldingBounds.bottom = oriRight;</p> 
        <p class="p padding-l60"> }</p> 
      <p class="p padding-l30">//rotation为0是垂直半折的机器</p> 
      <p class="p padding-l30"> } else {</p> 
        <p class="p padding-l60"> if (currentMaxWindowRect.height() != foldingBounds.height()) {</p> 
          <p class="p padding-l90"> int oriLeft = foldingBounds.left;</p> 
          <p class="p padding-l90"> int oriRight = foldingBounds.right;</p> 
          <p class="p padding-l90"> foldingBounds.left = foldingBounds.top;</p> 
          <p class="p padding-l90"> foldingBounds.top = oriLeft;</p> 
          <p class="p padding-l90"> foldingBounds.right = foldingBounds.bottom;</p> 
          <p class="p padding-l90"> foldingBounds.bottom = oriRight;</p> 
        <p class="p padding-l60"> }</p> 
      <p class="p padding-l30"> } </p> 
      <p class="p padding-l30">//当前叠屏状态数据，建议应用可以保存上一次的数据。</p> 
      <p class="p padding-l30">CommonFoldingFeature curFoldingFeature = new CommonFoldingFeature(foldingBounds, foldingFeature.getType(), foldingFeature.getState());</p> 
      <p class="p padding-l30">if (curFoldingFeature.getState() == CommonFoldingFeature.STATE_FLAT) {</p> 
        <p class="p padding-l60"> //enterNormalMode() 展开态</p> 
      <p class="p padding-l30">} else if (curFoldingFeature.getState() == CommonFoldingFeature.STATE_HALF_OPENED) {</p> 
        <p class="p padding-l60"> if (curFoldingFeature.getBounds().width() > curFoldingFeature.getBounds().height()) {</p> 
          <p class="p padding-l90">//enterTabletopMode()  Orientation.HORIZONTAL 水平半折态</p> 
        <p class="p padding-l60">} else {</p> 
          <p class="p padding-l90">//enterBookMode() Orientation.VERTICAL  垂直半折态</p> 
        <p class="p padding-l60">}</p> 
      <p class="p padding-l30">} else {</p> 
      <p class="p padding-l30">}</p> 
      <p class="p ">}</p> 
      <p class="p ">private String getFeatureString() {</p> 
      <p class="p padding-l30"> String settingsFeature = Settings.Global.getString(mResolver, DISPLAY_FEATURES);</p> 
      <p class="p padding-l30"> if (TextUtils.isEmpty(settingsFeature)) {</p> 
      <p class="p padding-l60"> settingsFeature = "";</p> 
      <p class="p padding-l30"> }</p> 
      <p class="p padding-l30"> return settingsFeature;</p> 
      <p class="p ">}</p> 
      <br>
      <br>
      <p class="p ">//字符串解析</p> 
      <p class="p ">private  CommonFoldingFeature parseFromString(@NonNull String string) {</p> 
      <p class="p padding-l30"> if (TextUtils.isEmpty(string)) {</p> 
      <p class="p padding-l60">  return null;</p> 
      <p class="p padding-l30"> }</p> 
      <p class="p padding-l30"> Matcher featureMatcher = FEATURE_PATTERN.matcher(string);</p> 
      <p class="p padding-l30"> if (!featureMatcher.matches()) {</p> 
      <p class="p padding-l60"> throw new IllegalArgumentException("Malformed feature description format: " + string);</p> 
      <p class="p padding-l30"> }</p> 
      <p class="p padding-l30">  try {</p> 
        <p class="p padding-l60"> String featureType = featureMatcher.group(1);</p> 
        <p class="p padding-l60"> featureType = featureType == null ? "" : featureType;</p> 
        <p class="p padding-l60"> int type;</p> 
        <p class="p padding-l60"> switch (featureType) {</p> 
          <p class="p padding-l90"> case FEATURE_TYPE_FOLD:</p> 
            <p class="p padding-l120"> type = CommonFoldingFeature.TYPE_FOLD;</p> 
            <p class="p padding-l120"> break;</p> 
          <p class="p padding-l90"> case FEATURE_TYPE_HINGE:</p> 
            <p class="p padding-l120"> type = CommonFoldingFeature.TYPE_HINGE;</p> 
            <p class="p padding-l120"> break;</p> 
          <p class="p padding-l90"> default: {</p> 
            <p class="p padding-l120"> throw new IllegalArgumentException("Malformed feature type: " + featureType);</p> 
          <p class="p padding-l90"> }</p> 
        <p class="p padding-l60"> }</p> 

        <p class="p padding-l60"> int left = Integer.parseInt(featureMatcher.group(2));</p> 
        <p class="p padding-l60"> int top = Integer.parseInt(featureMatcher.group(3));</p> 
        <p class="p padding-l60"> int right = Integer.parseInt(featureMatcher.group(4));</p> 
        <p class="p padding-l60"> int bottom = Integer.parseInt(featureMatcher.group(5));</p> 
        <p class="p padding-l60"> Rect featureRect = new Rect(left, top, right, bottom);</p> 
        <p class="p padding-l60"> if (isZero(featureRect)) {</p> 
          <p class="p padding-l90">  throw new IllegalArgumentException("Feature has empty bounds: " + string);</p> 
        <p class="p padding-l60"> }</p> 
        <p class="p padding-l60"> String stateString = featureMatcher.group(6);</p> 
        <p class="p padding-l60"> stateString = stateString == null ? "" : stateString;</p> 
        <p class="p padding-l60"> final int state;</p> 
        <p class="p padding-l60"> switch (stateString) {</p> 
          <p class="p padding-l90"> case PATTERN_STATE_FLAT:</p> 
            <p class="p padding-l120"> state = CommonFoldingFeature.STATE_FLAT;</p> 
            <p class="p padding-l120"> break;</p> 
          <p class="p padding-l90"> case  PATTERN_STATE_HALF_OPENED:</p> 
            <p class="p padding-l120"> state = CommonFoldingFeature.STATE_HALF_OPENED;</p> 
            <p class="p padding-l120"> break;</p> 
          <p class="p padding-l90"> default: </p> 
            <p class="p padding-l120"> state = -1;</p> 
            <p class="p padding-l120"> break;</p> 
        <p class="p padding-l60"> }</p> 
        <p class="p padding-l60">  return new CommonFoldingFeature(featureRect, type, state);</p> 
      <p class="p padding-l30"> catch (NumberFormatException e) {</p> 
      <p class="p padding-l60"> throw new IllegalArgumentException("Malformed feature description: " + string, e);</p> 
      <p class="p padding-l30"> }</p> 
      <p class="p ">}</p> 
      <br>
      <br>
      <p class="p ">private boolean isZero(@NonNull Rect rect) {</p> 
      <p class="p padding-l30"> return rect.height() == 0 && rect.width() == 0;</p> 
      <p class="p ">}</p> 
      <br>
      <br>
      <p class="p ">//CommonFoldingFeature为悬停监听封装的数据结构</p> 
      <p class="p ">private class CommonFoldingFeature {</p> 
      <br>
      <p class="p padding-l30"> public static final int TYPE_FOLD = 1;</p> 
      <br>
      <p class="p padding-l30"> public static final int TYPE_HINGE = 2;</p> 
      <br>
      <p class="p padding-l30"> public static final int STATE_FLAT = 1;</p> 
      <br>
      <p class="p padding-l30"> public static final int STATE_HALF_OPENED = 2;</p> 
      <br>
      <p class="p padding-l30"> //分割线位置</p> 
      <p class="p padding-l30"> private final Rect mBounds;</p> 
      <br>
      <p class="p padding-l30"> //屏幕类型 fold或者hinge，目前findN系列都是fold</p> 
      <p class="p padding-l30"> private final int mType;</p> 
      <br>
      <p class="p padding-l30"> //折叠状态 half_open或者flat状态</p> 
      <p class="p padding-l30"> private final int mState;</p> 
      <p class="p padding-l30">public CommonFoldingFeature(Rect bounds, int type, int state) {</p> 
        <p class="p padding-l60"> mBounds = new Rect(bounds);</p> 
        <p class="p padding-l60"> mType = type;</p> 
        <p class="p padding-l60"> mState = state;</p> 
      <p class="p padding-l30">}</p> 
      <br>
      <p class="p padding-l30"> public Rect getBounds() {</p> 
        <p class="p padding-l60"> return new Rect(mBounds);</p> 
      <p class="p padding-l30">}</p> 
      <br>
      <p class="p padding-l30"> public int getType() {</p> 
        <p class="p padding-l60"> return mType;</p> 
      <p class="p padding-l30">}</p> 
      <br>
      <p class="p padding-l30"> public int getState() {</p> 
        <p class="p padding-l60"> return mState;</p> 
      <p class="p padding-l30">}</p> 

      <p class="p padding-l30"> private  String typeToString(int type) {</p> 
        <p class="p padding-l60"> switch (type) {</p> 
          <p class="p padding-l90"> case TYPE_FOLD:</p> 
            <p class="p padding-l120"> return "FOLD";</p> 
          <p class="p padding-l90"> case TYPE_HINGE:</p> 
            <p class="p padding-l120"> return "HINGE";</p> 
          <p class="p padding-l90"> default: </p> 
            <p class="p padding-l120"> return "Unknown feature type (" + type + ")";</p> 
        <p class="p padding-l60"> }</p> 
      <p class="p padding-l30">}</p> 

      <p class="p padding-l30"> private  String stateToString(int state) {</p> 
        <p class="p padding-l60"> switch (state) {</p> 
          <p class="p padding-l90"> case STATE_FLAT:</p> 
            <p class="p padding-l120">  return "FLAT";</p> 
          <p class="p padding-l90"> case STATE_HALF_OPENED:</p> 
            <p class="p padding-l120"> return "HALF_OPENED";</p> 
          <p class="p padding-l90"> default: </p> 
            <p class="p padding-l120"> return "Unknown feature state (" + state + ")";</p> 
        <p class="p padding-l60"> }</p> 
      <p class="p padding-l30">}</p> 

      <p class="p padding-l30"> public String toString() {</p> 
        <p class="p padding-l60"> return "ExtensionDisplayFoldFeature { " + mBounds + ", type=" + typeToString(getType()) + ", state=" + stateToString(mState) + " }";</p> 
      <p class="p padding-l30">}</p> 

      <p class="p ">}</p> 
    </div>
    
  </div>
</template>

<script>
export default {
  props: ['scrollContainer'],
  data() {
    return {
    };
  },
  created() {
    
  },
};
</script>

<style scoped lang="scss">
.h1 {
  color: rgb(51, 51, 51);
  font-size: 32px;
  font-weight: bold;
}
.h2 {
  font-size: 22px;
  color: rgb(64, 158, 255);
  font-weight: bold;
}
.h3 {
  font-size: 18px;
  color: rgb(51, 51, 51);
  font-weight: bold;
}
.h4 {
  font-size: 17px;
  color: rgb(89, 89, 89);
  font-weight: bold;
}
.h5 {
  font-size: 16px;
  color: rgb(89, 89, 89);
  font-weight: bold;
}
.p {
  display: block;
  font-size: 16px;
  color: rgb(89, 89, 89);
  line-height: 28px;
}
.p-small {
  display: block;
  font-size: 13px;
  color: rgb(89, 89, 89);
  line-height: 24px;
}
.a {
  color: rgb(64, 158, 255);
}
.w400 {
  width: 400px;
}
.w600 {
  display: block;
  width: 600px;
}
.w750 {
  display: block;
  width: 750px;
}
.code {
  padding: 20px 40px;
  border: 1px solid #f29d1e;
  background-color: #ffffcc;
}
table {
  border-collapse: collapse;
  th,
  td {
    border: 1px solid #000;
    padding: 10px;
  }
}
ul {
  li {
    list-style: disc;
  }
}
</style>